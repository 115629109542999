$(document).on('click', '.btn_inifinitescroll', function(e) {
    var pag = document.getElementById('btn_inifinitescroll').dataset.pag;
    var path = $("link[rel='canonical']").attr("href");

    params = $(location).attr('href').split('?');
    if (typeof params[1] !== 'undefined') {
        page = params[1].split('page=')
        if (typeof page[1] !== 'undefined') {
            var get_url = params[0] + '?' + page[0] + 'page=' + pag + '&from-xhr';
            var url_uri = params[0] + '?' + page[0] + 'page=' + pag;
        } else {
            var get_url = params[0] + '?' + page[0] + '&page=' + pag + '&from-xhr';
            var url_uri = params[0] + '?' + page[0] + '&page=' + pag;
        }
    } else {
        var get_url = params[0] + '?page=' + pag + '&from-xhr';
        var url_uri = params[0] + '?page=' + pag;
    }

    const template = `<div class="faceted-overlay">
    <div class="overlay__inner">
    <div class="overlay__content"><span class="spinner"></span></div>
    </div>
    </div>`;

    $('body').append(template);

    $.ajax({
        url: get_url,
        type: 'GET',
        dataType: 'json',

        success: function(json) {

            items_shown_to = json.pagination.items_shown_to;
            total_items = json.pagination.total_items;
            porcentaje = items_shown_to * 100 / total_items;


            $('.pagination').remove();
            $('#js-product-list').append(json.rendered_products);

            if (porcentaje < 100) {
                pag_next = parseInt(pag) + 1;
                $('#btn_inifinitescroll').attr('data-pag', pag_next);
            } else {
                $('#btn_inifinitescroll').remove();
            }

            history.pushState({}, null, url_uri);
        },
        
        error: function(xhr, status) {
            alert('Hubo un problema con la carga, intentelo de nuevo');
        },

        complete: function(xhr, status) {
            $('.faceted-overlay').remove();
        }
    });

});

$(document).ready(function(){
    var $el, $ps, $up, totalHeight;
    $(document).on("click", "#left-column .category-top-menu .read-more .button", function() {

        totalHeight = 0

        $el = $(this);
        $p  = $el.parent();
        $up = $p.parent();
        $ps = $up.find("p:not('.read-more')");

        $ps.each(function() {
            totalHeight += $(this).outerHeight();
        });

        $up
            .css({
                "height": 'auto',
                "max-height": 9999
            })

        $p.fadeOut();

        return false;
    });
});

$(document).on('click', '.btn-readmore', function () {
    if ($(this).hasClass('active')) {
        $('#category-description .row').removeClass('active')
        $(this).removeClass('active')
    } else {
        $('#category-description .row').addClass('active')
        $(this).addClass('active')
    }
})

$(document).on('click', '#btn-search-mobile', function () {
    modalSearcher()
})

$(document).on('click', '#btn-search-mobile-close', function () {
	modalSearcher()
})

function modalSearcher() {
    if ($('#search_widget').hasClass('active')) {
        $('#search_widget').removeClass('active')
    } else {
        $('#search_widget').addClass('active')
    }
}